import BaseComponent from "../../BaseComponent";

export default class RespondentSelection extends BaseComponent
{
    private previousAttributeOptions: string[][] = [];
    private loadRespondentsSignalUrl;

    private selectedOptions = [];

	public init(): void {
        this.loadRespondentsSignalUrl = this.options?.loadRespondentsSignal;
        if (!this.loadRespondentsSignalUrl) {
            throw Error("RespondentSelection missing data-options loadRespondents.");
        }

		const $selectionToggle = this.$component.find('[data-selection-toggle]');
		const $selectionControls = this.$component.find('[data-selection-controls]');
		const $respondentSelection = this.$component.find('[data-respondent-selection]');

        const $attributeSelects = this.$component.find('[data-attribute-select]');
		const $attributeOptionsSelects = <JQuery<HTMLSelectElement>>this.$component.find('select[data-attribute-option-select]');

        // Prevent submit of form by incident enter hit
        this.$component.find('[data-attribute-box]').on('keydown', e => {
            if (e.key === 'Enter') {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
            }
        });

        // close selectized inputs on click outside
        $(document).on('click', (e) => {
            if ($(e.target).closest('.selectize-control').length) {
                return;
            }
            this.$component.find('select').each((index, element) => {
                element.selectize?.blur();
            })
        })

        $attributeSelects.each((i,select) => {
            $(select).selectize({
                allowEmptyOption: true,
                onFocus: () => {
                    $attributeOptionsSelects.each((i, el) => el.selectize.blur());
                    $attributeSelects.each((i,el)=> {
                        if (el !== select) {
                            el.selectize.blur();
                        }
                    });
                }
            });
        });
        $attributeOptionsSelects.each((i,select) => {
            $(select).selectize();
            select.selectize.disable();
        });

        $attributeSelects.on('change', (e) => {

            const attributeSelect = <HTMLSelectElement>e.currentTarget;
            const $attributeSelect = $(attributeSelect);
            const $attributeOptionSelect = $attributeSelect.parent().find('[data-attribute-option-select]');

            const attributeId = <string>$attributeSelect.val();
            const options = this.options.attributes[attributeId]?.options ?? [];

            $attributeOptionSelect[0].selectize?.destroy();
            $attributeOptionSelect.selectize({
                plugins: ["remove_button", "autofill_disable"],
                maxItems: null,
                valueField: 'id',
                labelField: 'name',
                searchField: 'name',
                options: options,
                create: false,
                onFocus: () => {
                    $attributeSelects.each((i,el)=> el.selectize.blur());
                    $attributeOptionsSelects.each((i,el)=> {
                        if (el !== $attributeOptionSelect[0]) {
                            el.selectize.blur();
                        }
                    });
                },
                onChange: () => this.reloadRespondents(),
            });
            if (options.length > 0) {
                attributeSelect.selectize.blur();
                $attributeOptionSelect[0].selectize.enable();
                $attributeOptionSelect[0].selectize.focus();
            } else {
                $attributeOptionSelect[0].selectize.disable();
            }

            this.updateAttributeBoxesVisibility();

            this.reloadRespondents();
        });

        let checkAllCheckboxes = false;
		$respondentSelection.find('[data-select-all]').on('click', e => {
			const $checkboxes = $respondentSelection.find('[data-respondent-checkbox] input')
				.filter(function() {
				return $(this).closest('div').css('display') !== 'none';
			});
			$checkboxes.prop('checked', checkAllCheckboxes = !checkAllCheckboxes);
		});

		this.$component.find('[data-respondent-checkbox] input').on('change', (e: JQuery.ChangeEvent<any, undefined, HTMLInputElement, any>) => {
			this.$component.find('[data-select-all-checkbox] input').prop('checked', false);
			if (e.currentTarget.checked) {
				this.$component.find('[data-select-new-checkbox] input').prop('checked', false);
			} else {
				this.$component.find('[data-select-new-checkbox] input').prop('checked', this.areAllNewUsersChecked());
			}
		});

		this.$component.find('[data-enable-selection-button]').on('click', (e) => {
			e.preventDefault();
			$selectionToggle.prop('checked', true).parent().hide();
			$selectionControls.show();
		});

		this.$component.find('[data-disable-selection-button]').on('click', (e) => {
			e.preventDefault();
			$selectionToggle.prop('checked', false).parent().show();
			$selectionControls.hide();
		});

		this.$component.find('[data-add-respondents-button]').on('click', (e) => {
			e.preventDefault();
			this.addRespondents();
		});

		this.$component.find('[data-save-respondents-button]').on('click', (e) => {
			e.preventDefault();
			this.removeOrAddRespondents();
		});

		this.$component.find('[data-remove-respondents-button]').on('click', (e) => {
			e.preventDefault();
			this.removeRespondents();
		});

		this.$component.find('[data-remove-all-respondents-button]').on('click', (e) => {
			e.preventDefault();
			this.removeAllRespondents();
		});

		this.$component.find('[data-open]').on('click', (e) => {
			e.preventDefault();
		});

		this.$component.find('[data-close]').on('click', (e) => {
			e.preventDefault();

			const $parentModal = this.$component.closest('[data-reveal]');

			if ($parentModal.length) {
				$parentModal.modal('show');
			}
		});

		this.$component.find('[data-add-by-ids-button]').on('click', (e) => {
			e.preventDefault();
			this.addRespondentsByIds();
		});

		this.$component.find('[data-select-new-checkbox]').on('click', (e) => {
            this.selectNewUsers(e.currentTarget);
		});


		this.resetSeparators();

        (function hookEvents(){
            function checkRelated(select) {
                const selected = select.find(':selected');//$('#allAttributeValues select option:selected');
                $('#respondentList input').prop('checked', false);
                if (selected.lenght && selected.data('users') !== undefined) {
                    selected.data('users').forEach(function (id) {
                        $('#respondentList input[value="' + id +'"]').prop('checked', true);
                    });
                }
            }
            const attributeValueSelect = '#allAttributeValues select';
            $('#respondentFilter select').not(attributeValueSelect).on('change', function () {
                $(attributeValueSelect).hide();
                $(attributeValueSelect + '[data-attribute="' + $(this).val() + '"]').show().trigger('change');
            });
            $(attributeValueSelect).on('change', function () {
                checkRelated($(this));
            });
        })();

		this.updateNoRespondentsMessage();
        this.updateAttributeBoxesVisibility();
	}

    private reloadRespondents(): void {
        const attributes = this.readAttributeValues();
        const attributeOptions = this.readAttributeOptionsValues();

        let wasEmpty = false;
        const requestValues = attributes.map((a,i) => {
             if (!wasEmpty || (i < 2 && a)) {
                 return attributeOptions[i];
             }
             wasEmpty = true;
             return [];
        });

        if (JSON.stringify(requestValues) !== JSON.stringify(this.previousAttributeOptions)) {
            this.context.naja
                .makeRequest('POST', this.loadRespondentsSignalUrl, JSON.stringify(requestValues))
                .then(payload => {
                    this.previousAttributeOptions = requestValues;
                    const data = payload.respondentSelection;
                    this.createCheckboxes(data.filteredRespondents);
                });
        }
    }

    private updateAttributeBoxesVisibility(): void {
        const values = this.readAttributeValues().map(Number);
        let lastIndexWithValue = 0;
        values.forEach((value, i) => {
            if (value) {
                lastIndexWithValue = i;
            }
        });

        const boxesVisible = {
            0:2, 1:4, 2:4, 3:6, 4:6, 5:6
        }[lastIndexWithValue] ?? lastIndexWithValue;

        this.$component.find('[data-attribute-box]').each((i, box) => {
             if ( i < boxesVisible ) {
                 $(box).show();
             } else {
                 $(box).hide();
             }
        });
    }

    private readAttributeValues(): string[] {
        return Array.from( this.$component.find('[data-attribute-select]') )
            .map( (select: HTMLSelectElement) => select.value );
    }

    private readAttributeOptionsValues(): string[][] {
        const attributeOptions: string[][] = [];

        this.$component.find('[data-attribute-option-select]').each((i, optionsSelect) => {
            const $optionsSelect = $(optionsSelect);
            const optionIds =  <string[]>$optionsSelect.val();
            attributeOptions.push( optionIds.filter(v => v) );
        });

        return attributeOptions;
    }

	private createCheckboxes(items): void {
		const $respondentSelection = this.$component.find('[data-respondent-selection]');
		const $respondentList = $respondentSelection.find('[data-respondent-list]');
		const $template = this.$component.find('[data-respondent-checkbox-template]');

		$respondentSelection.find('[data-select-all-checkbox]').hide().find('input').prop('checked', false);
		$respondentList.find('[data-respondent-checkbox]').hide();

		if (Object.keys(items).length > 0) {
			$respondentSelection.find('[data-select-all-checkbox]').show();
		}

		for (let key in items) {
			if (items.hasOwnProperty(key)) {
				let value = items[key];

				const $existingObject = this.$component.find(`.form-check-input[value="${key}"]`).first().closest('div');
				if ($existingObject.length) {
					$existingObject.show();
				} else {
					const $item = $template.clone();
					$item.find('[data-input]').val(key);
					$item.find('[data-label]').append(value);
					$item.removeAttr('data-respondent-checkbox-template').show().appendTo($respondentList);
				}
			}
		}
        this.checkCheckboxesBySelectedRespondents();
	}

	private getFilteredRespondentsSelectionIds(): string[] {
		const respondents = [];

		this.$component.find('[data-respondent-list] input:checked').each((i, input: HTMLInputElement) => {
			if (!respondents.includes(input.value)) {
				respondents.push(input.value)
			}
		});

		return respondents;
	}

	private removeOrAddRespondents(): void {
		this.$component.find('[data-selected-respondents] input').prop('checked', false).parent().hide();

		const respondentIds = this.getFilteredRespondentsSelectionIds();
		const $unselectedRespondents = this.$component.find('[data-selected-respondents] input:not(:checked)');

		$unselectedRespondents.each((i, input: HTMLInputElement) => {
			if (respondentIds.indexOf(input.value) !== -1) {
				$(input).prop('checked', true).parent().show();
			}
		});

		this.processRespondentCounter();
		this.resetSeparators();
        this.updateNoRespondentsMessage();
	}

	private addRespondents(ids = null): void {
		const respondentIds = ids ? ids : this.getFilteredRespondentsSelectionIds();
		const $unselectedRespondents = this.$component.find('[data-selected-respondents] input:not(:checked)');

		$unselectedRespondents.each((i, input: HTMLInputElement) => {
			if (respondentIds.includes(input.value)) {
				$(input).prop('checked', true).parent().show();
			}
		});
		this.checkCheckboxesBySelectedRespondents();
		this.processRespondentCounter()
		this.resetSeparators();
        this.updateNoRespondentsMessage();
	}

	private addRespondentsByIds(): void {
		const $textarea = this.$component.find('[data-respondent-ids-textarea]');
		const value = <string>$textarea.val() || '';
		this.addRespondents(value.match(/[^\r\n\s,;a-z\-]+/g));
		$textarea.val('');

		const $parentModal = this.$component.find('#addById');
		$parentModal.modal('hide');
		this.updateNoRespondentsMessage();
	}

	private processRespondentCounter(): void {
		const h4Element = this.$component.find('[data-selected-respondents-title]')[0];
		let counter = this.getFilteredRespondentsSelectionIds().length;
		if (h4Element) {
			const h4ElementTitle = h4Element.getAttribute('data-selected-respondents-title');
			if (h4ElementTitle) {
				if (counter > 0) {
					h4Element.textContent = h4ElementTitle + ' (' + counter + ')' + ':';
				} else {
					h4Element.textContent = h4ElementTitle + ':';
				}
			}
		}
	}

	private removeRespondents(removeAll = false): void {
		const respondentIds = this.getFilteredRespondentsSelectionIds();
		const $selectedRespondents = this.$component.find('[data-selected-respondents] input:checked')

		$selectedRespondents.each((i, input: HTMLInputElement) => {
			if (removeAll || respondentIds.includes(input.value)) {
				$(input).prop('checked', false).parent().hide();
			}
		});

		if (removeAll) {
			this.$component.find('[data-respondent-checkbox] input').prop('checked', false);
			this.$component.find('[data-select-all-checkbox] input').prop('checked', false);
			this.$component.find('[data-select-new-checkbox] input').prop('checked', false);
		}
		this.checkCheckboxesBySelectedRespondents();
		this.processRespondentCounter()
		this.resetSeparators();
		this.updateNoRespondentsMessage();
	}

	private removeAllRespondents(): void {
		this.removeRespondents(true);
	}

	private resetSeparators(): void {
		const $respondents = this.$component.find('[data-selected-respondents] label');

		$respondents.removeClass('last-selected');
		$respondents.find('input:checked').last().parent().addClass('last-selected');
	}

	private areAllNewUsersChecked(): boolean {
		const ids = this.$component.find('[data-select-new-checkbox]').data('ids')?.toString()?.split(",") || [];
		const checked = ids.filter((id) => $('[data-respondent-checkbox] input[value="'+id+'"]').is(':checked'));
		return checked.length === ids.length;
	}

	private selectNewUsers(e): void {
        // check / uncheck
        $(e).children('input').prop('checked', !$(e).children('input').is(':checked'));
        let ids = $(e).data('ids').toString().split(',');
        const check = $(e).children('input').is(':checked');
        ids.forEach((id) => {
            $('[data-respondent-checkbox] input[value="' + id + '"]').prop('checked', check);
        });
	}

	private updateNoRespondentsMessage(): void {
	    const $message = this.$component.find('[data-no-respondents-selected-message]')
	    if (this.$component.find('[data-selected-respondents] input:checked').length) {
            $message.hide()
        } else {
            $message.removeClass('info-box').addClass('error-box');
	        $message.show();
        }

    }

	private checkCheckboxesBySelectedRespondents() {
		this.$component.find('[data-respondent-checkbox] input').prop('checked', false);
		const respondentIds = this.getFilteredRespondentsSelectionIds();
		const $unselectedRespondents = this.$component.find('[data-respondent-list] input');

		$unselectedRespondents.each((i, input: HTMLInputElement) => {
			if (respondentIds.includes(input.value)) {
				$(input).prop('checked', true).parent().show();
			}
		});
	}

}
